import './app.scss';

window.addEventListener('DOMContentLoaded', function () {
    const domain = `${window.location.origin}/${document.documentElement.lang}/jobs`;
    console.info(
        `%c  ___ _____      _____      _ _      _ _        _  
 | _ \\ __\\ \\    / / __|  __| (_)__ _(_) |_ __ _| | 
 |   / _| \\ \\/\\/ /| _|  / _\` | / _\` | |  _/ _\` | | 
 |_|_\\___| \\_/\\_/ |___| \\__,_|_\\__, |_|\\__\\__,_|_| 
                               |___/               
                                                   `,
        'background: #00195a; color: #d2eb23; font-size:24px;',
        `\n\nconsole.log('Find your new home at REWE digital!');`,
        `\n\nconsole.log('Apply now at ${domain}');`
    );

    const contentUpdateEvent = new CustomEvent('content.loaded', {
        view: window,
        bubbles: true,
        cancelable: false,
        detail: {
            $context: document
        }
    });

    window.dispatchEvent(contentUpdateEvent);

    // Remove no Transitions for all Elements at Start
    setTimeout(() => {
        document.body.classList.remove('has--no-animation');
    }, 1000);
});
